import axios from 'axios';
import constant from '../constant';

var authService = {
    login: function (username, password) {
        console.log("try login")
        console.log(constant.api_service + '/api/auth');
        return constant.axios.get('/auth',
            {
                // "collection": "users",
                // "conditions": {
                //     "uid": username, "password": password
                // },
                auth: {
                    username, password
                }

            },
        ).then(result => {
            console.log(result)

            if (result.data.status == false) {
                console.log("Throw");

                throw "Password or Username is incorrect.";
            }

            // localStorage.setItem("token","tempTokenAddLater");
            sessionStorage.setItem('token', "tempTokenAddLater");

            localStorage.setItem("username", username);
            localStorage.setItem("password", password);

            return result.data;
        })
            .catch(error => {
                console.log(error);
                throw error;
            });
    },
    register: function () {

    },
    getUserByName: function (username) {
        console.log("getUserByName");

        var password = localStorage.getItem("password");

        return axios.get(constant.api_service + '/api/getUserDetails',
            {
                // "collection": "users",
                // "conditions": {
                //     "uid": username, "password": password
                // },
                auth: {
                    username: username,
                    password: password
                }
            }
        ).then(result => {
            console.log(result);
            if (result.data.length == 0) {
                console.log("Throw");

                throw "Password or Username is incorrect.";
            }

            return result.data;
        })
            .catch(error => { console.log(error); throw error; });
    },
}

export default authService;