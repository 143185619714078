import enMessages from '../locales/en_US';

const EnLangRtl = {
  messages: {
    ...enMessages,
  },
  locale: 'en-US',
};

export default EnLangRtl;
