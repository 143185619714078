const constant = {
  // api_rpass:"http://rpass.roborn.com:28080",
  api_actionSocket: "https://rpass.roborn.com",
  api_dataSocket: "https://rpass.roborn.com",
  api_image: "https://rpass.roborn.com/api/getMap?rid=%rid%&mapName=%mapName%",
  api_service: "https://rpass.roborn.com",
  streamingEndPoint: "https://rpass.roborn.com:38081",
  data_robots: [
    {
      rid: "bw1001",
      defaultMap: "roborn811",
      brand: "Roborn",
      robotName: "bw1001",
      aliasName: "bw1001",
      isOnline: true,
      lastOnline: "2021-07-21",
      ip: "192.168.0.1",
      image: "https://www.pngmart.com/files/3/Wall-E-PNG-Transparent-Image.png",
      streaming: {
        label: 'bw1002',
        streamingURL: "https://rpass.roborn.com:38081",
        room: 'bw1002'
      },
      routes: [
        { id: 1, location: "1/F", startTime: "09:00", endTime: "11:59" },
        { id: 2, location: "2/F", startTime: "12:00", endTime: "12:59" },
        { id: 3, location: "3/F", startTime: "15:00", endTime: "15:59" }
      ]
    },
    {
      rid: "bw1002",
      defaultMap: "roborn811",
      brand: "Roborn",
      robotName: "bw1002",
      aliasName: "bw1002",
      isOnline: true,
      lastOnline: "2021-07-21",
      ip: "192.168.0.1",
      image: "https://www.pngmart.com/files/3/Wall-E-PNG-Transparent-Image.png",
      streaming: {
        label: 'bw1002',
        streamingURL: "https://rpass.roborn.com:38081",
        room: 'bw1002'
      },
      routes: [
        { id: 1, location: "1/F", startTime: "09:00" },
        { id: 2, location: "2/F", startTime: "12:00" },
        { id: 3, location: "3/F", startTime: "15:00" }
      ]
    },
    {
      rid: "bw1003",
      defaultMap: "roborn811",
      brand: "Roborn", robotName: "bw1003", aliasName: "bw1003", isOnline: false, lastOnline: "2021-07-21", ip: "192.168.0.1", image: "https://www.pngmart.com/files/3/Wall-E-PNG-Transparent-Image.png",
      streaming: {
        label: 'bw1002',
        streamingURL: "https://rpass.roborn.com:38081",
        room: 'bw1002'
      },
      routes: [
        { id: 1, location: "1/F", startTime: "09:00" },
        { id: 2, location: "2/F", startTime: "12:00" },
        { id: 3, location: "3/F", startTime: "15:00" }
      ]
    },
    {
      rid: "bw1004",
      defaultMap: "roborn811",
      brand: "Roborn", robotName: "bw1004", aliasName: "bw1004", isOnline: true, lastOnline: "2021-07-21", ip: "192.168.0.1", image: "https://www.pngmart.com/files/3/Wall-E-PNG-Transparent-Image.png",
      streaming: {
        label: 'bw1002',
        streamingURL: "https://rpass.roborn.com:38081",
        room: 'bw1002'
      },
      routes: [
        { id: 1, location: "1/F", startTime: "09:00" },
        { id: 2, location: "2/F", startTime: "12:00" },
        { id: 3, location: "3/F", startTime: "15:00" }
      ]
    },
    {
      rid: "bw1005",
      defaultMap: "roborn811",
      brand: "Roborn", robotName: "bw1005", aliasName: "bw1005", isOnline: true, lastOnline: "2021-07-21", ip: "192.168.0.1", image: "https://www.pngmart.com/files/3/Wall-E-PNG-Transparent-Image.png",
      streaming: {
        label: 'bw1002',
        streamingURL: "https://rpass.roborn.com:38081",
        room: 'bw1002'
      },
      routes: [
        { id: 1, location: "1/F", startTime: "09:00" },
        { id: 2, location: "2/F", startTime: "12:00" },
        { id: 3, location: "3/F", startTime: "15:00" }
      ]
    },
  ],
  data_3dmap: [
    {
      id: 1,
      name: "Roborn 8/F 3D Map",
      thumbnailUrl: "https://www.programmableweb.com/sites/default/files/02_13.png",
      jsAPIKey: "23684fd02c6f4b35269d2247f0cbeeb9",
      indoorMapId: "EIM-e46bea41-68dd-4036-a4d6-87be3ddb5045",
      indoorMapFloorId: "8",
      latitude: 22.2590989,
      longitude: 114.1320415,
      coverageTreeManifest: "https://webgl-cdn1.wrld3d.com/chunk/indoor_maps/api_requests/EIM-e46bea41-68dd-4036-a4d6-87be3ddb5045_2021_07_29_09_41_38/webgl_manifest.bin.gz",
      slideBarSelectedRobots: [
        "bw1004", "bw1003"
      ],
      mapRobots: [
        {
          id: "bw1003",
          floorIndex: 1,
          name: "bw1003",
          robotX: 255,
          robotY: 485,
          currentX: 0,
          currentY: 0,
          currentLat: 22.25897,
          currentLng: 114.13237,
          moveLatPerX: 0,
          moveLngPerX: 0,
          moveLatPerY: 0,
          moveLngPerY: 0,
          left: 0,
          top: 0,
          routeRecords: [],
          ratioOfHeight: 0,
          ratioOfWidth: 0,
          robotIcon: "https://image.flaticon.com/icons/png/512/48/48717.png",
          display: true
        },
        {
          id: "bw1004",
          floorIndex: 1,
          name: "bw1004",
          robotX: 255,
          robotY: 485,
          currentX: 0,
          currentY: 0,
          currentLat: 22.25893,
          currentLng: 114.13237,
          moveLatPerX: 0,
          moveLngPerX: 0,
          moveLatPerY: 0,
          moveLngPerY: 0,
          left: 0,
          top: 0,
          routeRecords: [],
          ratioOfHeight: 0,
          ratioOfWidth: 0,
          robotIcon: "https://image.flaticon.com/icons/png/512/48/48717.png",
          display: true
        }
      ]
    },
    {
      id: 2,
      name: "Roborn 3/F 3D Map",
      thumbnailUrl: "https://www.programmableweb.com/sites/default/files/02_13.png",
      jsAPIKey: "23684fd02c6f4b35269d2247f0cbeeb9",
      indoorMapId: "EIM-e46bea41-68dd-4036-a4d6-87be3ddb5045",
      indoorMapFloorId: "3",
      latitude: 22.2590989,
      longitude: 114.1320415,
      coverageTreeManifest: "https://webgl-cdn1.wrld3d.com/chunk/indoor_maps/api_requests/EIM-e46bea41-68dd-4036-a4d6-87be3ddb5045_2021_07_29_09_41_38/webgl_manifest.bin.gz",
      slideBarSelectedRobots: [
        "bw1004", "bw1003"
      ],
      mapRobots: [
        {
          id: "bw1003",
          floorIndex: 1,
          name: "bw1003",
          robotX: 100,
          robotY: 100,
          currentX: 0,
          currentY: 0,
          currentLat: 22.25897,
          currentLng: 114.13237,
          moveLatPerX: 0,
          moveLngPerX: 0,
          moveLatPerY: 0,
          moveLngPerY: 0,
          left: 0,
          top: 0,
          routeRecords: [],
          ratioOfHeight: 0,
          ratioOfWidth: 0,
          robotIcon: "https://image.flaticon.com/icons/png/512/48/48717.png",
          display: true
        },
        {
          id: "bw1004",
          floorIndex: 1,
          name: "bw1004",
          robotX: 254,
          robotY: 483,
          currentX: 0,
          currentY: 0,
          currentLat: 22.25893,
          currentLng: 114.13237,
          moveLatPerX: 0,
          moveLngPerX: 0,
          moveLatPerY: 0,
          moveLngPerY: 0,
          left: 0,
          top: 0,
          routeRecords: [],
          ratioOfHeight: 0,
          ratioOfWidth: 0,
          robotIcon: "https://image.flaticon.com/icons/png/512/48/48717.png",
          display: true
        }
      ]
    }
  ],
  data_streamingRobots: [
    {
      label: 'pepdemo',
      streamingURL: "https://rpass.roborn.com:38081",
      room: 'pepdemo',
      gridIndex: 0

    },
    {
      label: 'pep1',
      streamingURL: "https://rpass.roborn.com:38081",
      room: 'pep1',
      gridIndex: 1
    },
    {
      label: 'bw1002',
      streamingURL: "https://rpass.roborn.com:38081",
      room: 'bw1002',
      gridIndex: 2

    },
    {
      label: 'pep2',
      streamingURL: "https://rpass.roborn.com:38081",
      room: 'pep2',
      gridIndex: 3

    }
  ],
  data_schedule: {
    "rid": "aobodemo",
    "request": "getScheduleState",
    "sid": "UNBnk3OJb5cVgtg2AAAx",
    "params": [],
    "body": {},
    "data": {
      "type": "schedule",
      "name": "r811",
      "days": [
        1,
        2,
        3,
        4
      ],
      "isLoop": true,
      "state": "active",
      "tasks": [
        {
          "request": "rotate",
          "angle": 35.1,
          "startTime": "2021-09-12T09:29:48.064Z",
          "id": "a8a3fac2-863e-48d6-8351-e5b9e46ebdd1",
          "state": "fail"
        },
        {
          "request": "point",
          "name": "gotopoint_a",
          "startTime": "2021-09-12T09:29:51.069Z",
          "id": "67dff533-ca1a-485d-b34d-9d3e42bdc3e1",
          "state": "pending"
        },
        {
          "request": "wait",
          "time": 1200,
          "startTime": "2021-09-12T09:29:23.995Z",
          "state": "fail"
        },
        {
          "request": "path",
          "name": "gotopath_b",
          "startTime": "2021-09-12T09:29:36.031Z",
          "id": "e608be6d-7b89-44d3-b470-b194ead32172",
          "state": "fail"
        },
        {
          "request": "path",
          "name": "gotopath_b",
          "startTime": "2021-09-12T09:29:36.031Z",
          "id": "e608be6d-7b89-44d3-b470-b11242172",
          "state": "fail"
        },
        {
          "request": "path",
          "name": "gotopath_b",
          "startTime": "2021-09-12T09:29:36.031Z",
          "id": "e608be6d-7b89-44d3-b470-b194ea1243172",
          "state": "fail"
        },
        {
          "request": "path",
          "name": "gotopath_b",
          "startTime": "2021-09-12T09:29:36.031Z",
          "id": "e608be6d-7b89-44d3-b470-b1941232154172",
          "state": "fail"
        },
        {
          "request": "path",
          "name": "gotopath_b",
          "startTime": "2021-09-12T09:29:36.031Z",
          "id": "e608be6d-7b89-44d3-b470-b194e1232154172",
          "state": "fail"
        },
        {
          "request": "path",
          "name": "gotopath_b",
          "startTime": "2021-09-12T09:29:36.031Z",
          "id": "e608be6d-7b89-44d3-b470-b19412312532172",
          "state": "fail"
        },
        {
          "request": "path",
          "name": "gotopath_b",
          "startTime": "2021-09-12T09:29:36.031Z",
          "id": "e608be6d-7b89-44d3-b470-b194e12321432172",
          "state": "fail"
        },
        {
          "request": "path",
          "name": "gotopath_b",
          "startTime": "2021-09-12T09:29:36.031Z",
          "id": "e608be6d-7b89-44d3-b470-b194ead1254214172",
          "state": "fail"
        },
        {
          "request": "path",
          "name": "gotopath_b",
          "startTime": "2021-09-12T09:29:36.031Z",
          "id": "e608be6d-7b89-44d3-b470-b194ead31232",
          "state": "fail"
        }
      ],
      "current": {
        "request": "point",
        "name": "gotopoint_a",
        "startTime": "2021-09-12T09:29:51.069Z",
        "id": "67dff533-ca1a-485d-b34d-9d3e42bdc3e1",
        "state": "pending"
      }

    },
    "status": true,
    "msg": ""
  },
  axios : require('axios').create({
    baseURL: 'https://rpass.roborn.com/api',
    auth: {
      username: localStorage.getItem("username") || 'kevin',
      password: localStorage.getItem("password") || 'kevin',
    },
    headers: { 'Content-Type': 'application/json' }
  })
}
// constant.axios = require('axios').create({
//   baseURL: 'https://rpass.roborn.com/api',
//   auth: {
//     username: localStorage.getItem("username") || 'kevin',
//     password: localStorage.getItem("password") || 'kevin',
//   },
//   headers: { 'Content-Type': 'application/json' }
// });
// Add a response interceptor
constant.axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log("debug interceptors", response)
  if (response.data.data) {
    response.data = response.data.data
  }
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
})

export default constant;