import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';
import { NotificationContainer } from './components/common/react-notifications';
import { isMultiColorActive, adminRoot } from './constants/defaultValues';
import { getDirection } from './helpers/Utils';
import { ProtectedRoute, UserRole } from './helpers/authHelper';
import { io } from 'socket.io-client'
import GlobalContext from './GlobalContext';
import constant from './constant';
import robotService from './services/robotService';

export const MyProtectedRoute = ({ component: Component, ...rest}) => {
  const isLogin = sessionStorage.getItem('token')
  return (
    <Route {...rest}
      render={props => (isLogin ? <Component {...rest} {...props} /> :
        <Redirect to='/login' />)}
    />
  )
}

document.addEventListener('touchstart', function addTouchClass(e) { // first time user touches the screen
  document.documentElement.classList.add('can-touch') // add "can-touch" class to document root using classList API
  document.removeEventListener('touchstart', addTouchClass, false) // de-register touchstart event
}, false)

const ViewHome = React.lazy(() =>
  import(/* webpackChunkName: "views" */ './views/home')
);
const ViewApp = React.lazy(() =>
  import(/* webpackChunkName: "views-app" */ './views/app')
);
const ViewUser = React.lazy(() =>
  import(/* webpackChunkName: "views-user" */ './views/user')
);
const ViewError = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ './views/error')
);
const ViewUnauthorized = React.lazy(() =>
  import(/* webpackChunkName: "views-unauthorized" */ './views/unauthorized')
);
const ViewLogin = React.lazy(() =>
  import(/* webpackChunkName: "views-login" */ './views/app/auth/auth')
);

const cloud_url = 'http://rpass.roborn.com';
const localhost_url = 'http://rpass.roborn.com';
const test_url = 'http://192.168.8.104';
const base_url = localhost_url;

const dataSocketUrl = `${base_url}:28080`
const actionSocketUrl = `${base_url}:28081`
const uid = 'admin'
const password = 'admin'

class App extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      globalMessage: "",
      globalMessageColor: "success",
      globalMessageVisible: false,
      actionSocket: io(constant.api_actionSocket, {
        auth: {
          uid: uid,
          password: password
        }
      }),
      robots: [],
      robotsStatus: [],
    }


    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
    } else {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
    }

    // HttpRequestHelper.getDb().then(res => {
    //   props.dispatch({
    //     type: 'SET_USER_ROBOTS',
    //     payload: res.data.data[0].robots,
    //   })
    //   props.dispatch({
    //     type: 'SET_SMART_MAPS',
    //     payload: res.data.data[0].smartmaps,
    //   })
    // })

    //Connection websocket if loggedin
    if (sessionStorage.getItem('token')) {  //Modify here later if check token api is completed

    }
  }

  componentDidMount() {

    robotService.getAllRobots().then(results => {
      if(results){
        this.setState({"robotsStatus":results})
        this.setState({"robots":results.map(o => o.rid)});
        this.state.actionSocket.emit("subscribe", results.map(o => (
          {
            rid: o.rid,
            requests: ["getPosition","getScheduleState"]
          } 
        )));
        //this.setupBeforeUnloadListener(results.map(o => o.rid))
        window.addEventListener('beforeunload', this.handleLeavePage);
      }



    });

  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage);
  }


  handleLeavePage = () => {
    this.state.actionSocket.emit('unsubscribe', this.state.robots)
    this.state.actionSocket.close();
  };

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];


    const defaultValue = {
      actionSocket: this.state.actionSocket,
      globalMessage: this.state.globalMessage,
      globalMessageVisible: this.state.globalMessageVisible,
      globalMessageColor: this.state.globalMessageColor,
      setGlobalMessage: (val) => this.setState({ globalMessage: val }),
      setGlobalMessageVisible: (val) => this.setState({ globalMessageVisible: val }),
      setGlobalMessageColor: (val) => this.setState({ globalMessageColor: val }),
      pushGlobalMessage: (message, color) => {
        this.setState({ globalMessageVisible: true })
        this.setState({ globalMessageColor: color });
        this.setState({ globalMessage: message });

      }
    }


    return (
      //  <DataSocketContext.Provider value={dataSocket}>
      //  <ActionSocketContext.Provider value={actionSocket}>
      <GlobalContext.Provider value={defaultValue}>

        <div className="h-100">
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <>
              <NotificationContainer />
              {/* {isMultiColorActive && <ColorSwitcher/>} */}
              <Suspense fallback={<div className="loading" />}>
                <Router>
                  <Switch>
                    <MyProtectedRoute
                      path={adminRoot}
                      component={ViewApp}
                      robotList={this.state.robotsStatus}
                      roles={[UserRole.Admin, UserRole.Editor]}
                    />
                    <Route
                      path="/user"
                      render={(props) => <ViewUser {...props} />}
                    />
                    <Route
                      path="/error"
                      exact
                      render={(props) => <ViewError {...props} />}
                    />
                    <Route
                      path="/unauthorized"
                      exact
                      render={(props) => <ViewUnauthorized {...props} />}
                    />
                    <Route
                      path="/login"
                      exact
                      render={(props) => <ViewLogin {...props} />} />

                    <Redirect exact from="/" to={adminRoot} />
                    <Redirect to="/error" />
                  </Switch>
                </Router>
              </Suspense>
            </>
          </IntlProvider>
        </div>

      </GlobalContext.Provider>

      // </ActionSocketContext.Provider>
      // </DataSocketContext.Provider> 
    );
  }
}

const mapStateToProps = ({ authUser, settings, robots }) => {
  const { currentUser } = authUser;
  const { locale } = settings;
  return { currentUser, locale, robots };
};
const mapActionsToProps = {};

export default connect(mapStateToProps)(App);

