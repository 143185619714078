import axios from 'axios';
import constant from '../constant';
import authService from './authService';



var robotService = {

    addRobots: function (rid, brand, robotName, aliasName, ip, imageUrl) {
        var username = localStorage.getItem("username");
        var password = localStorage.getItem("password");


        var user = authService.getUserByName(username);

        var target = user.robots.filter(o => o.rid == rid)[0];

        if (!target) {
            user.robots.push({
                rid: rid,
                defaultMap: "",
                brand: brand,
                robotName: robotName,
                aliasName: aliasName,
                isOnline: false,
                lastOnline: "",
                ip: ip,
                image: imageUrl,
                streaming: {
                    label: rid,
                    streamingURL: "https://rpass.roborn.com:38081",
                    room: rid
                },
                routes: []
            });
        }

        console.log(user)

        throw "Robot already exist";
    },
    updateRobot: async function (rid, brand, robotName) {
        var username = localStorage.getItem("username");
        var password = localStorage.getItem("password");

        var users = await authService.getUserByName(username);

        users.robots.filter(o => o.rid == rid)[0].brand = brand;
        users.robots.filter(o => o.rid == rid)[0].robotName = robotName;


        console.log(users);

        return axios.post(constant.api_service + '/db/update', {
            "collection": "users",
            "conditions": {
                "uid": username
            },
            "update": users
        }, {
            auth: {
                username: username,
                password: password
            }
        }
        ).then(result => {
            var value = result.data;
            console.log(result);
            return value;
        })
            .catch(error => { console.log(error); throw error; });
    },
    updateRobotLidarMap: async function (rid, lidarMap) {
        var username = localStorage.getItem("username");
        var password = localStorage.getItem("password");

        var uid = localStorage.getItem("username");

        var users = await authService.getUserByName(uid);

        if (users.robots.filter(o => o.rid == rid)[0].components.filter(o => o.type == "lidarmap").length > 0) {
            users.robots.filter(o => o.rid == rid)[0].components.filter(o => o.type == "lidarmap")[0].data.name = lidarMap;
        } else {
            users.robots.filter(o => o.rid == rid)[0].components.push({
                "type": "lidarmap",
                "data": {
                    "name": lidarMap
                }
            })
        }

        return axios.post(constant.api_service + '/api/updateRobot', {
            "rid": rid,
            "update": users.robots.filter(o => o.rid == rid)[0]
        }, {
            auth: {
                username: username,
                password: password
            }
        }
        ).then(result => {
            var value = result.data;
            console.log(result);
            return value;
        })
            .catch(error => { console.log(error); throw error; });
    },
    getAllRobots: function () {
        var username = localStorage.getItem("username");
        var password = localStorage.getItem("password");

        console.log("getAllRobots");

        return authService.getUserByName(username).then((o) => {
            // o.robots.forEach(o => {
            //     if (o.image == null) {
            //         o.image = "https://www.pngmart.com/files/3/Wall-E-PNG-Transparent-Image.png";
            //     }
            // })
            return o.robots;
        }).catch(err => {
            throw err;
        });

    },
    getRobotByRid: function (rid) {
        console.log("getRobotByRid");
        var username = localStorage.getItem("username");
        var password = localStorage.getItem("password");

        return authService.getUserByName(username).then((o) => {
            console.log(o.robots.filter(r => r.rid == rid)[0])

            //if no lidarmap in component, add a fake lidarmap data with empty name
            if (o.robots.filter(r => r.rid == rid)[0].components.filter(o => o.type == "lidarmap").length == 0) {
                o.robots.filter(r => r.rid == rid)[0].components.push({
                    type: "lidarmap",
                    data: {
                        name: "",
                    }
                })
            }
            // else{
            //     o.robots.filter(r=>r.rid == rid)[0].components.filter(o=>o.type == "lidarmap")[0].data.name = "roborn811"
            // }

            return o.robots.filter(r => r.rid == rid)[0];
        }).catch(err => {
            throw err;
        });
    },
    getCameraLayout: function () {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem("layout")) {
                resolve(JSON.parse(localStorage.getItem("layout")))
            }

            resolve([]);
        })

    },
    addCameraLayout: function (item) {
        return new Promise((resolve, reject) => {
            var arr = localStorage.getItem("layout");
            arr.push(item);

            localStorage.setItem("layout", JSON.stringify(arr))


            resolve(arr);
        })

    },
    updateCameraLayout: function (layout) {
        return new Promise((resolve, reject) => {
            console.log("updateCameraLayout")

            console.log(layout)
            localStorage.setItem("layout", JSON.stringify(layout))
            resolve(layout);
        })

    },
    getDashboardLayout: function () {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem("dashboardlayout")) {
                resolve(JSON.parse(localStorage.getItem("dashboardlayout")))
            }

            resolve([]);
        })

    },
    addDashboardLayout: function (item) {
        return new Promise((resolve, reject) => {
            var arr = localStorage.getItem("dashboardlayout");
            arr.push(item);

            localStorage.setItem("dashboardlayout", JSON.stringify(arr))


            resolve(arr);
        })

    },
    updateDashboardLayout: function (layout) {
        return new Promise((resolve, reject) => {
            console.log("updatedashboardlayout")

            console.log(layout)
            localStorage.setItem("dashboardlayout", JSON.stringify(layout))
            resolve(layout);
        })

    },
    getDashboardBackground: function () {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem("DashboardBackground") != "undefined") {
                resolve(localStorage.getItem("DashboardBackground"))
            }

            resolve("");
        })

    },
    addDashboardBackground: function (item) {
        return new Promise((resolve, reject) => {
            localStorage.setItem("DashboardBackground", item)
            resolve(item);
        })

    },
    updateDashboardBackground: function (item) {
        return new Promise((resolve, reject) => {
            localStorage.setItem("DashboardBackground", item)
            resolve(item);
        })

    },
    getStreamingList: async function () {
        var username = localStorage.getItem("username");
        var user = await authService.getUserByName(username);

        return new Promise((resolve, reject) => {
            var streamings = [];
            user.robots.forEach(o => {
                if (o.components.filter(o => o.type == "stream").length > 0) {
                    streamings.push(o.components.filter(o => o.type == "stream")[0]);
                }
            });

            console.log("getStreamingList");
            console.log(streamings);
            resolve(streamings);
        })
    },
    getLidarMapList: async function (rid) {
        var username = localStorage.getItem("username");
        var password = localStorage.getItem("password");

        console.log("getLidarMapList")

        return constant.axios.post('/request', {
            rid: rid,
            request: "getMapList",
            params: [],
            body: {},

        }
        ).then(result => {
            console.log("Get result on getLidarMapList")
            console.log(result)

            if (result.data && result.data) {
                var value = result.data;
                console.log("getLidarMapList");
                console.log(result);
                return value;
            }

            return [];
        }).catch(error => {
            console.log("throw error on getLidarMapList")
            console.log(error);
            throw error;
        }
        );
    },
    getLidayMapByRidAndMapName: async function (rid, mapName) {
        var username = localStorage.getItem("username");
        var password = localStorage.getItem("password");

        return axios.get(constant.api_image.replace("%rid%", rid).replace("%mapName%", mapName), {
            "auth": {
                username: username,
                password: password
            },
            responseType: 'arraybuffer'
        }
        ).then(response => {
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    '',
                ),
            );
            //const imageObjectURL = URL.createObjectURL(resp.data);
            //var id = (resp.rid + "_" + o.name).replace(" ","");
            return "data:;base64," + base64;

        })
            .catch(error => { console.log(error); throw error; });

    }
}



export default robotService;