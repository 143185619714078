import React from 'react';

const GlobalContext = React.createContext({
    rpassSocket: null,
    actionSocket:null,
    toggleTheme: () => {},
    username:"",
    password:"",
    globalMessage:"",
    globalMessageVisible:false,
    globalMessageColor:"success",
    setGlobalMessage:null,
    setGlobalMessageVisible:null,
    setGlobalMessageColor:"null",
    pushGlobalMessage: function pushGlobalMessage(message,color){
      
    }
  });
  
  export default GlobalContext;
  